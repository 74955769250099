import React from 'react';
import firebase from '../../../components/firebase/Firebase'

import logoutIcon from '../assets/log-out.svg'
import logo from '../assets/logo-1.png'
import back from '../assets/back.svg'
import { withRouter } from 'react-router';

const Header = (props) => {
    const logout = () => {
        firebase.auth().signOut()
    }

    return (
        <div className="dashboard-header">
            {(props.userLogged || props.isBackNeeded) && <div className="logout-button back-button" onClick={() => props.history.push('/')}><img src={back} alt="back" /></div>}
            <img src={logo} alt="worktoday" />
            <div className="dashboard-header-details">
                {props.userLogged && <h2>{"Viewing: " + props.userLogged.name}</h2>}
                <h1>{props.user.name}</h1>
                <div className="logout-button" onClick={logout}><img src={logoutIcon} alt="logout" /></div>
            </div>
        </div>
    );
}
 
export default withRouter(Header);