import moment from 'moment';
import React, { useState } from 'react';

const History = ({ history, historyDate }) => {
    const [months] = useState(["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])
    
    const renderDays = () => {
        if (!history) {
            return (
                <div className="dashboard-history-day">
                    <h1>There is no records for {months[moment(historyDate).month()]}</h1>
                </div>
            )
        }

        let arr = []

        let total = 0
        arr = history && Object.keys(history).length && Object.keys(history).map((key) => {
            let val = history[key]
            console.log("val: ", val)
            total += val
            val = val * 60 * 60

            let hour = Math.floor(val / (60 * 60))
            val = val - (hour * 60 * 60)
            let minute = Math.round((val / 60))

            return (
                <div key={key} className="dashboard-history-day">
                    <h1 className="day">{key}</h1>
                    <div className="dashboard-history-hour"><h2>{hour}</h2><h1>:</h1><h2>{minute}</h2></div>
                </div>
            )
        })

        // let totalHour = Math.floor(total)
        // let totalMinute = Math.floor((parseFloat(total) - totalHour) * 60)

        console.log("total: ", total)
        total = total * 60 * 60
        let totalHour = Math.floor(total / (60 * 60))
        total = total - (totalHour * 60 * 60)
        let totalMinute = Math.round((total / 60))

        let totalDiv = (
            <div key={"total"} className="dashboard-history-day">
                <h1 className="day total">Total {months[moment(historyDate).month()]}</h1>
                <div className="dashboard-history-hour"><h2>{totalHour}</h2><h1>:</h1><h2>{totalMinute}</h2></div>
            </div>
        )

        arr = [totalDiv, ...arr]
        return arr
    }
    return (
        <div className="dashboard-history">
            {
                renderDays()
            }
        </div>
    );
}
 
export default History;