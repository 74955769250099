import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: "AIzaSyCzVInTjidYv-NFMXIpkGFfwXEwlI6qTUk",
	authDomain: "worktoday-2e027.firebaseapp.com",
	databaseURL: "https://worktoday-2e027-default-rtdb.firebaseio.com",
	projectId: "worktoday-2e027",
	storageBucket: "worktoday-2e027.appspot.com",
	messagingSenderId: "154691659253",
	appId: "1:154691659253:web:5e9d494114cf36f069ecbc",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase