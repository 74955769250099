import React from 'react';
import addIcon from '../assets/add.svg'
import minusIcon from '../assets/minus.svg'

const NumberPicker = ({ step, max, label, isDisabled, value, onChange }) => {
    const increment = () => {
        let newNumber = value + step
        onChange(Math.min(newNumber, max))
    }

    const decrement = () => {
        let newNumber = value - step
        onChange(Math.max(newNumber, 0))
    }

    return (
        <div className="number-picker-wrapper">
            <h1>{label}</h1>
            <div className={"number-picker " + (isDisabled ? "disabled" : "")}>
                {!isDisabled ? <button onClick={increment}><img src={addIcon} alt="increment" /></button> : ""}
                <h1>{value}</h1>
                {!isDisabled ? <button onClick={decrement}><img src={minusIcon} alt="decrement" /></button> : ""}
            </div>
        </div>
    );
}
 
export default NumberPicker;