import moment from 'moment';
import React, { useState } from 'react';
import deleteIcon from '../assets/trash.svg'
import restoreIcon from '../assets/restore.svg'

const SearchItem = ({item, idx, onClick, isHeader, deleteUser}) => {
    const [months] = useState(["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])
    
    // let hour = Math.floor(item.totalHours) || 0
    // let minute = Math.floor((parseFloat(item.totalHours) - hour) * 60) || 0

    let val = item.totalHours * 60 * 60
    let hour = Math.floor(val / (60 * 60)) || 0
    val = val - (hour * 60 * 60)
    let minute = Math.round((val / 60)) || 0

    return (
        <div className={"search-item-wrapper " + (isHeader ? "header" : "")} onClick={isHeader ? () => {} : () => onClick(idx)}>
            <h1>{isHeader ? months[moment().month()] + " Total" : item.name}</h1>
            <h2>{item.email}</h2>
            <div><h3>{hour}</h3><h4> : </h4><h3>{minute}</h3></div>
            {!isHeader && (
                <div
                    className={item.isDeleted ? "delete-button restore-button" : "delete-button"}
                    onClick={(e) => {e.stopPropagation(); deleteUser(item.uid)}}
                >
                    <img src={item.isDeleted ? restoreIcon : deleteIcon} alt={item.isDeleted ? "restore" : "delete"} />
                </div>
            )}
        </div>
    );
}
 
export default SearchItem;