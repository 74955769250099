import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import firebase from '../../components/firebase/Firebase'

import './Dashboard.css'
import Header from './components/Header';
import NumberPicker from './components/NumberPicker';
import Loader from '../../components/loader/Loader';
import History from './components/History';

const Dashboard = (props) => {
    const [date, setDate] = useState(moment());
    const [historyDate, setHistoryDate] = useState(moment())
    const [history, setHistory] = useState(null)
    const [isHistorySynced, setIsHistorySynced] = useState(false)
    const [hourCount, setHourCount] = useState(0)
    const [minuteCount, setMinuteCount] = useState(0)
    const [isSynced, setIsSynced] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
    const [userLogged, setUserLogged] = useState(null)

    const [months] = useState(["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])

    const changeDate = (value) => {
        if (date.month() !== value.month() || date.year() !== value.year()) {
            setIsHistorySynced(false)
            setHistoryDate(value)
        }

        setDate(value)
    }

    useEffect(() => {
        if (isHistorySynced) {
            return
        }

        let uid = props.user.uid
        if (props.user.isAdmin) {
            if (!userLogged) {
                return
            }
            uid = userLogged.uid
        }

        firebase.database().ref(`days/${historyDate.year()}/${uid}/${months[historyDate.month()]}`).once('value', snapshot => {
            if (snapshot.exists()) {
                setHistory(snapshot.val())
            } else {
                setHistory(undefined)
            }
        })

        setIsHistorySynced(true)
    }, [historyDate, isHistorySynced, months, props.user.isAdmin, props.user.uid, userLogged])

    useEffect(() => {
        setLoading(true)
        if (!props.user.isAdmin) {
            setLoading(false)
            return
        }

        let { uid } = props.match.params

        if (!uid) {
            props.history.push('/')
        }

        firebase.database().ref(`users/${uid}`).once('value', snapshot => {
            if (snapshot.exists()) {
                let userLogged = { ...snapshot.val(), uid: snapshot.key }
                setUserLogged(userLogged)
                setLoading(false)
            } else {
                props.history.push('/')
            }
        })
    }, [props.history, props.match.params, props.user.isAdmin])

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth < 768) {
                setIsMobile(true)
            } else if (window.innerWidth >= 768) {
                setIsMobile(false)
            }
        }

        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])



    useEffect(() => {
        if (isSynced) {
            return
        }

        // console.log("user: ", props.user, "userlogged: ", userLogged)

        let uid = props.user.uid
        if (props.user.isAdmin) {
            if (!userLogged) {
                return
            }
            uid = userLogged.uid
        }

        setLoading(true)
        let ref = firebase.database().ref(`/days/${date.year()}/${uid}/${months[date.month()]}/${date.date()}`)
        ref.once('value', (snapshot) => {
            if (snapshot.exists()) {
                // setAlreadySubmitted(moment().date() !== moment(date).date())
                let val = snapshot.val() * 60 * 60
                let hour = Math.floor(val / (60 * 60))
                val = val - (hour * 60 * 60)
                let minute = Math.floor((val / 60))

                setHourCount(hour)
                setMinuteCount(minute)
                setIsSynced(true)
                setLoading(false)
            } else {
                // setAlreadySubmitted(false)
                setHourCount(0)
                setMinuteCount(0)
                setIsSynced(true)
                setLoading(false)
            }
        })
    }, [date, isSynced, months, props.match.params.uid, props.user.isAdmin, props.user.uid, userLogged])

    const submit = () => {
        setLoading(true)

        let month = months[date.month()]

        let prev = 0
        let updateValue = hourCount + minuteCount / 60
        let uid = props.user.uid
        let name = props.user.name
        if (props.user.isAdmin) {
            uid = userLogged.uid
            name = userLogged.name
        }

        let ref = firebase.database().ref(`/days/${date.year()}/${uid}/${months[date.month()]}`)
        let ref2 = ref.child(date.date())
        let previousDay = 0
        ref2.once('value', snapshot => {
            if (snapshot.exists()) {
                previousDay = parseFloat(snapshot.val()) || 0
            }
        }).then(() => {
            ref.update({
                [date.date()]: updateValue
            }).then(() => {
                firebase.database().ref(`/sheets/${date.year()}/${uid}`).once('value', (snapshot) => {
                    if (snapshot.exists()) {
                        let node = snapshot.val()
                        prev = parseFloat(node[month] || 0) || 0
                    }
        
                    firebase.database().ref(`/sheets/${date.year()}/${uid}`).update({
                        [month]: prev - previousDay + updateValue,
                        Name: name
                    }).then(() => {
                        setIsSynced(false)
                        setIsHistorySynced(false)
                        setLoading(false)
                    })
                })
            })
        })
    }

    // let isDisabled = moment().date() !== moment(date).date()

    return (
        <div className="dashboard-wrapper">
            <Header user={props.user} userLogged={userLogged} />

            <History history={history} historyDate={historyDate} />

            <div className="dashboard-container">
                <div className="dashboard-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            autoOk
                            orientation={isMobile ? "portrait" : "landscape"}
                            variant="static"
                            openTo="date"
                            value={date}
                            disableFuture={false}
                            onChange={(value) => {setIsSynced(false); changeDate(value);}}
                        />
                    </MuiPickersUtilsProvider>
                </div>

                {
                    loading ? (
                        <div className="dashboard-details">
                            <Loader />
                        </div>
                    ) : (
                        <div className="dashboard-details">
                            <h1>{"Enter the number of hours"}</h1>
                            <div className="dashboard-details-picker">
                                <NumberPicker
                                    step={1}
                                    max={24}
                                    onChange={(value) => setHourCount(value)}
                                    label="Hours"
                                    value={hourCount}
                                />
                                <NumberPicker
                                    step={5}
                                    max={60}
                                    onChange={(value) => setMinuteCount(value)}
                                    label="Minutes"
                                    value={minuteCount}
                                />
                            </div>
                            <button className="primary blue-button" onClick={submit}>Submit</button>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
 
export default Dashboard;