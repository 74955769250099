import React, { useState } from 'react';
import AlertMessage from '../../components/alertMessage/AlertMessage';

import firebase from '../../components/firebase/Firebase'

import logo from './assets/logo-1.png'

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const login = (e) => {
        e.preventDefault()

        firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
            props.history.push('/')
        }).catch(err => {
            console.log(err)
            switch (err.code) {
                case "auth/user-not-found":
                    setError("Wrong username or password")
                    break;
                case "auth/invalid-email":
                    setError("The email address is invalid")
                    break;
                case "auth/wrong-password":
                    setError("Wrong username or password")
                    break;
                default:
                    break;
            }
        })
    }

    const resetPassword = () => {
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            // Email sent.
            setSuccess("A password reset email has been sent to you.")
          }).catch(function(error) {
            // An error happened.
            console.log(error)
            setError("Please check your email address, then try again")
          });
    }

    return (
        <div className="register-wrapper" onSubmit={login}>
            <AlertMessage isOpen={error} message={error} type={"ERROR"} onClose={() => {console.log("closing"); setError("")}} />
            <AlertMessage isOpen={success} message={success} type={"SUCCESS"} onClose={() => {console.log("closing"); setSuccess("")}} />
            <div className="register-container">
                <img src={logo} alt="logo" />
                <h1>Login zum Erfassen der Lektionen</h1>
                <input className="primary" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" />
                <input className="primary" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" />
                <h3 onClick={resetPassword}>Passwort vergessen ?</h3>

                <button className="primary blue-button" onClick={login}>Login</button>
                <h2>Don't have an account ?</h2>
                <button className="primary" onClick={() => props.history.push('/register')}>Register</button>
            </div>
        </div>
    );
}

export default Login;