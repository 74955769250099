import React from 'react'

import successIcon from './assets/check.svg'
import errorIcon from './assets/error.svg'

import './AlertMessage.css'

const AlertMessage = ({ isOpen, type, message, onClose }) => {
    let icon = successIcon
    if (type === "ERROR") {
        icon = errorIcon
    }

    return (
        <div className={"alert-message-wrapper " + (isOpen ? "opened" : "")}>
            <div className="alert-message-container">
                <div className="alert-message-info">
                    <img src={icon} alt={type} />
                    <h1>{message}</h1>
                </div>

                <button className="primary blue-button" onClick={onClose}>OK</button>
            </div>
        </div>
    );
}
 
export default AlertMessage