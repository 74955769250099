import moment from 'moment';
import React, { useEffect, useState } from 'react';
import firebase from '../../components/firebase/Firebase'
import Header from '../dashboard/components/Header';
import SearchItem from './components/SearchItem';

import './Search.css'

const Search = (props) => {
    const [search, setSearch] = useState("");
    const [users, setUsers] = useState([])
    const [totalHours, setTotalHours] = useState(0)
    const [searchResults, setSearchResults] = useState([])
    const [months] = useState(["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])
    const [isSynced, setIsSynced] = useState(false)

    useEffect(() => {
        if (isSynced) return

        Promise.all([
            firebase.database().ref('users/').once("value"),
            firebase.database().ref(`sheets/${moment().year()}`).once('value')
        ]).then(([snapshot, sheetSnapshot]) => {
            // console.log(snapshot.val());
            let usersObj = snapshot.val()
            let sheetObj = sheetSnapshot.val()
            console.log(sheetObj)

            let usersArr = []
            for (let key in usersObj) {
                let user = usersObj[key]
                usersArr.push({ ...user, uid: key, totalHours: sheetObj && sheetObj[key] && sheetObj[key][months[moment().month()]] })
            }

            const usersFinalArr = Array.from(usersArr).sort((a, b) => {                
                return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
            });

            setIsSynced(true)
            setUsers(usersFinalArr)
        })
    }, [isSynced, months])

    useEffect(() => {
        let usersFiltered = users.filter((user) => user.name.toLowerCase().includes(search.trim().toLowerCase()))

        let totalHours = 0
        for (let i in usersFiltered) {
            if (usersFiltered[i].isDeleted) {
                continue
            }

            totalHours += usersFiltered[i].totalHours || 0
        }

        setTotalHours(totalHours)
        setSearchResults(usersFiltered)
        console.log(usersFiltered)
    }, [search, users])

    const onSelectUser = (idx) => {
        let user = searchResults[idx]
        props.history.push('dashboard/' + user.uid)
    }

    const updateUserState = (id) => {
        if (window.confirm(`Are you sure you want to ${!props.isViewingDeleted ? "delete" : "restore"} this user ?`)) {
            firebase.database().ref(`users/${id}`).update({
                isDeleted: !props.isViewingDeleted
            }).then(res => {
                console.log(res)
                setIsSynced(false)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    return (
        <div className="dashboard-wrapper">
            <Header user={props.user} isBackNeeded={props.isViewingDeleted} />

            <div className="dashboard-utils">
                <input 
                    className="primary search"
                    type="text" name="email"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search by name"
                />
                {
                    !props.isViewingDeleted && (
                        <button
                            className="primary red-button"
                            onClick={() => props.history.push('/deleted')}
                        >
                            View deleted users
                        </button>
                    )
                }
                
            </div>
        
            {
                !props.isViewingDeleted && <SearchItem item={{ totalHours }} isHeader />
            }
            {
                searchResults && searchResults.length && searchResults.map((user, idx) => (
                    ((!props.isViewingDeleted && !user.isDeleted) || (props.isViewingDeleted && user.isDeleted)) && <SearchItem key={user.uid} item={user} idx={idx} onClick={onSelectUser} deleteUser={updateUserState} />
                ))
            }
        </div>
    );
}
 
export default Search;