import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Loader from './components/loader/Loader';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/dashboard/Dashboard';

import firebase from './components/firebase/Firebase'
import Search from './pages/search/Search';

function App() {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(user => {
			if (!user) {
				setUser(null)
				setLoading(false)
			} else {
				firebase.database().ref('users/' + user.uid).once('value', snapshot => {
					setUser({ ...snapshot.val(), uid: user.uid })
					setLoading(false)
				}).catch(err => {
					console.log(err)
					alert("something went wrong, please try again later")
				})
			}
		})
	}, [])

	if (loading) {
		return (
			<div className="App">
				<Loader />
			</div>
		)
	}

	if (!user) {
		return (
			<div className="App">
				<BrowserRouter>
					<Switch>
						<Route path="/register" render={(props) => <Register {...props} />} />
						<Route path="/" render={(props) => <Login {...props} />} />
					</Switch>
				</BrowserRouter>
			</div>
		)
	}

	if (user.isAdmin) {
		return (
			<div className="App">
				<BrowserRouter>
					<Switch>
						{/*<Route path="/register" render={(props) => <Register {...props} />} />
						<Route path="/login" render={(props) => <Login {...props} />} />*/}
						<Route exact path="/" render={(props) => <Search {...props} user={user} />} />
						<Route exact path="/deleted" render={(props) => <Search {...props} isViewingDeleted user={user} />} />
						<Route exact path="/dashboard/:uid" render={(props) => <Dashboard {...props} user={user} />} />
					</Switch>
				</BrowserRouter>
			</div>
		);
	}

	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					{/*<Route path="/register" render={(props) => <Register {...props} />} />
					<Route path="/login" render={(props) => <Login {...props} />} />*/}
					<Route path="/" render={(props) => <Dashboard {...props} user={user} />} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
