import React, { useState } from 'react';
import AlertMessage from '../../components/alertMessage/AlertMessage';
import firebase from '../../components/firebase/Firebase'
import logo from './assets/logo-1.png'

import './Auth.css'

const Register = (props) => {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const register = (e) => {
        e.preventDefault()
        if (error) {
            return   
        }
        console.log(email, password)

        if (!email.trim()) {
            setError("Please enter an email address")
            return
        }

        if (!firstName.trim() || !lastName.trim()) {
            setError("Please enter your first and last names")
            return
        }

        if (password !== confirmPassword) {
            setError("Confirm password doesn't match your password")
            return
        }

        firebase.auth().createUserWithEmailAndPassword(email.trim(), password).then(user => {
            firebase.database().ref('users/' + user.user.uid).set({
                email,
                name: firstName.trim() + " " + lastName.trim()
            }).then(() => {
                props.history.push('/')
            }).catch(err => {
                setError(err.message)
            })
        }).catch(err => {
            console.log(err)
            switch (err.code) {
                case "auth/email-already-in-use":
                    setError("The email address is already used by another user")
                    break;
                case "auth/invalid-email":
                    setError("The email address is invalid")
                    break;
                case "auth/weak-password":
                    setError("The password you've chosen is weak, please choose a strong password")
                    break;
                default:
                    break;
            }
        })
    }

    return (
        <div className="register-wrapper" onSubmit={register}>
            <AlertMessage isOpen={error} message={error} type={"ERROR"} onClose={() => {console.log("closing"); setError("")}} />
            <div className="register-container">
                <img src={logo} alt="logo" />
                <h1>Register a new account</h1>
                <input className="primary" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                <input className="primary" type="text" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
                <input className="primary" type="text" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
                <input className="primary" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" />
                <input className="primary" type="password" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm your password" />
                <button className="primary blue-button" onClick={register}>Register</button>
                <h2>Already registered ?</h2>
                <button className="primary" onClick={() => props.history.push('login')}>Login</button>
            </div>
        </div>
    );
}
 
export default Register;